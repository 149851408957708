/* eslint-disable unicorn/prefer-module */
const React = require('react');
const { PATHS } = require('constants/paths');
const { RecaptchaWrapper } = require('components/recaptcha-wrapper');

const pathsWithForms = [
    PATHS.ESTIMATE_THE_PROJECT,
    PATHS.CONTACT_US,
    PATHS.CAREERS,
    PATHS.PRIVACY_POLICY,
    PATHS.RESOURCES,
    `${PATHS.RESOURCES}/${PATHS.CROSS_PLATFORM_WHITE_PAPER}`,
    `${PATHS.RESOURCES}/${PATHS.MEDICAL_DATA_PROTECTION}`,
];

const ConditionalRecaptchaProvider = ({ children, location }) => {
    const currentPath = location.pathname.startsWith('/')
        ? location.pathname.slice(1)
        : location.pathname;

    const shouldLoadRecaptcha = pathsWithForms.includes(currentPath);

    return shouldLoadRecaptcha ? (
        <RecaptchaWrapper>
            {children}
        </RecaptchaWrapper>
    ) : (
        children
    );
};

exports.wrapPageElement = ({ element, props }) => {
    return <ConditionalRecaptchaProvider location={props.location}>{element}</ConditionalRecaptchaProvider>;
};

exports.onRouteUpdate = () => {
    if (window.CLUTCHCO !== undefined) {
        window.CLUTCHCO.Init();
    }
};
