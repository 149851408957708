import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export const RecaptchaWrapper = ({ children }) => (
    <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_RECAPTCHA_V3_PUBLIC_KEY}
        scriptProps={{
            defer: true,
        }}
    >
        {children}
    </GoogleReCaptchaProvider>
);
