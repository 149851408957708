export const PATHS = {
    INDEX: '',
    DATA_SCIENCE: 'data-science',
    SOFTWARE_DEVELOPMENT_PROCESS: 'software-development-process',
    ESTIMATE_THE_PROJECT: 'estimate-the-project',
    FUNDS_EU: 'funds-eu',
    MOBILE_DEVELOPMENT: 'mobile-development',
    PRIVACY_POLICY: 'privacy-policy',
    TEAM: 'team',
    TERMS_SERVICE: 'terms-service',
    WEB_DEVELOPMENT: 'web-development',
    PROP_TECH_REAL_ESTATE: 'prop-tech-real-estate',
    AUDIO_VIDEO: 'audio-video',
    CAREERS: 'careers',
    EXPERTISES: 'expertises',
    TECHNOLOGIES: 'technologies',
    REACT_NATIVE: 'hire-react-native-developers',
    NODE_JS: 'hire-node-js-developers',
    REACT_JS: 'hire-react-js-developers',
    JAVA_SCRIPT: 'hire-javascript-developers',
    VUE_JS: 'hire-vue-js-developers',
    FLOW_CADENCE: 'hire-flow-blockchain-developers',
    AWS: 'hire-aws-developers',
    AWS_COST_OPTIMIZATION: 'aws-cost-optimization',
    R: 'hire-r-developers',
    NFT: 'nft',
    FINTECH: 'fintech',
    BLOG: 'blog',
    CONTACT_US: 'contact-us',
    SERVICES: 'services',
    CASE_STUDIES: 'software-development-case-studies',
    CONSTRUCTION_MANAGEMENT_SOFTWARE_TURO:
        'construction-management-software-turo',
    REAL_ESTATE_AUCTION_SOFTWARE: 'real-estate-auction-software',
    COMMERCIAL_REAL_ESTATE_SOFTWARE_ZONE:
        'commercial-real-estate-software-zone',
    NFT_REALITY: 'nft-reality',
    MUSIC_DISTRIBUTION_SOFTWARE: 'music-distribution-software',
    SURVEY_SOFTWARE: 'survey-software',
    TIPSTERS: 'sports-betting-tips-app',
    MONEY_TRANSFER_APPLICATION: 'money-transfer-software',
    DANCE_LIVE_STREAMING_SOFTWARE: 'dance-live-streaming-software',
    BANKING_SYSTEM_SOFTWARE: 'banking-system-software',
    AUDIO_MUSIC_MARKETPLACE: 'audio-music-marketplace',
    CRYPTOCURRENCY_EXCHANGE_SOFTWARE: 'cryptocurrency-exchange-software',
    PREGNABIT: 'pregnabit',
    SALES_FORECASTING_SOFTWARE: 'sales-forecasting-software',
    CUSTOMER_CHURN_PREDICTION_SOFTWARE: 'customer-churn-prediction-software',
    URBAN_ONE: 'urban-one',
    FACILITY_MANAGEMENT_SOFTWARE: 'facility-management-software',
    EXFLUENTIAL: 'exfluential',
    BUSINESS_INTELLIGENCE: 'business-intelligence',
    TECHNICAL_DUE_DILIGENCE: 'technical-due-diligence',
    OUR_PARTNERS: 'our-partners',
    FAQ: 'faq',
    RESOURCES: 'resources',
    CROSS_PLATFORM_WHITE_PAPER: 'cross-platform-white-paper',
    MEDICAL_DATA_PROTECTION: 'medical-data-protection',
    BACKLINKS: 'backlinks',
    PRESS_RELEASE: 'press-release',
    HEYWAY: 'social-media-app-development',
    PROPTECH: 'proptech',
    BLOCKCHAIN: 'blockchain',
    JAVASCRIPT: 'javascript',
    BUSINESS: 'business',
    WEBFLOW: 'webflow-agency',
    HR: 'hr',
};
